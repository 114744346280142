import { Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';
import React, { Component } from 'react';

const styles = StyleSheet.create({
  table: { width: '100%', marginTop: '20px' },
  table2: { width: '100%', marginTop: '2px' },
  row: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #000',
    paddingTop: 8,
    paddingBottom: 8,
  },
  header: { border: '1px solid #000', backgroundColor: 'tomato' },
  headerRadiologi: { border: '1px solid #000', backgroundColor: 'cornflowerblue' },
  headerTitle: { border: '1px solid #000', backgroundColor: 'grey' },
  bold: { fontWeight: 'bold' },
  row1: { width: '20%', borderRight: '1px solid #000' },
  row2: { width: '20%', borderRight: '1px solid #000' },
  row3: { width: '20%', borderRight: '1px solid #000' },
  row4: { width: '20%', borderRight: '1px solid #000' },
  row5: { width: '20%' },
  fontBold: { fontWeight: 'bold' },
  tandaVital: {
    row1: { width: '25%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row2: { width: '25%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row3: { width: '25%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row4: { width: '25%', textAlign: 'left', marginLeft: '5px' },
  },
  penunjang: {
    row1: { width: '33.2%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row2: { width: '33.2%', borderRight: '1px solid #000', textAlign: 'left', marginLeft: '5px' },
    row3: { width: '33.2%', textAlign: 'left', marginLeft: '5px' },
  },
  title: {
    row: { width: '100%', textAlign: 'left', marginLeft: '5px' }
  }
})

class PrintForm extends Component {
  state = {
    loginAs: localStorage.getItem('penunjang'),
  }

  render() {
    const { loginAs } = this.state;
    const { data } = this.props;
    const kelamin = data && data[0] && data[0]?.jenisKelamin;

    if (loginAs === 'Radiologi') {
      return (
        <div>
          <div style={{ borderBottom: '1px solid black', textAlign: 'right', padding: 5 }}>
            <Text style={{ fontSize: 18 }}>Hermina Arcamanik Hospital (RSU Hermina Arcamanik)</Text>
            <Text>Jl. A.H. Nasution No.50, Antapani Wetan, Kec. Antapani</Text>
            <Text>Kota Bandung, Jawa Barat 40291</Text>
            <Text>Phone: 022-87242525. Fax: 022-7271771</Text>
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Text>HASIL RADIOLOGI</Text>

            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.headerRadiologi]}>
                <Text style={styles.tandaVital.row1}>NO. RM</Text>
                <Text style={styles.tandaVital.row2}>NAMA</Text>
                <Text style={styles.tandaVital.row3}>TGL LAHIR</Text>
                <Text style={styles.tandaVital.row4}>DOKTER PENGIRIM</Text>
              </View>
              {data.map((row, i) => (
                <View key={i} style={styles.row} wrap={false}>
                  <Text style={styles.tandaVital.row1}>{row.noRM}</Text>
                  <Text style={styles.tandaVital.row2}>{row.namaLengkap}</Text>
                  <Text style={styles.tandaVital.row3}>{moment(row?.tglLahir).format('DD/MM/YYYY')}</Text>
                  <Text style={styles.tandaVital.row4}>{row.dokterPoli}</Text>
                </View>
              ))}
            </View>

            <View style={styles.table}>
              <View style={styles.row} wrap={false}>
                <Text style={styles.title.row}>{data && data[0] && data[0]?.hasilRadiologi?.keterangan}</Text>
              </View>
            </View>

            <Text style={{ textAlign: "right", marginTop: '30px', marginBottom: '30px' }}>PEMERIKSA</Text>
            <Text style={{ textAlign: "right" }}>{(data && data[0] && data[0]?.hasilRadiologi?.pemeriksa) || ''}</Text>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div style={{ borderBottom: '1px solid black', textAlign: 'right', padding: 5 }}>
            <Text style={{ fontSize: 18 }}>Hermina Arcamanik Hospital (RSU Hermina Arcamanik)</Text>
            <Text>Jl. A.H. Nasution No.50, Antapani Wetan, Kec. Antapani</Text>
            <Text>Kota Bandung, Jawa Barat 40291</Text>
            <Text>Phone: 022-87242525. Fax: 022-7271771</Text>
          </div>
          <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <Text>HASIL LABORATORIUM</Text>
  
            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.header]}>
                <Text style={styles.row1}>REGISTRASI</Text>
                <Text style={styles.row2}>NO. RM</Text>
                <Text style={styles.row3}>NAMA</Text>
                <Text style={styles.row4}>ALAMAT</Text>
                <Text style={styles.row5}>JENIS KELAMIN</Text>
              </View>
              {data.map((row, i) => (
                <View key={i} style={styles.row} wrap={false}>
                  <Text style={styles.row1}>{row.noRegistrasi}</Text>
                  <Text style={styles.row2}>{row.noRM}</Text>
                  <Text style={styles.row3}>{row.namaLengkap}</Text>
                  <Text style={styles.row4}>{row.alamat}</Text>
                  <Text style={styles.row5}>{row.jenisKelamin}</Text>
                </View>
              ))}
            </View>
  
            <View style={styles.table}>
              <View style={[styles.row, styles.bold, styles.header]}>
                <Text style={styles.penunjang.row1}>PEMERIKSAAN</Text>
                <Text style={styles.penunjang.row2}>HASIL</Text>
                <Text style={styles.penunjang.row3}>NILAI RUJUKAN</Text>
              </View>
              <View style={[styles.row, styles.headerTitle]} wrap={false}>
                <Text style={styles.title.row}>HEMATOLOGI</Text>
              </View>
              <View style={styles.row} wrap={false}>
                <Text style={styles.penunjang.row1}>Hemoglobin (HGB)</Text>
                <Text style={styles.penunjang.row2}>{(data && data[0] && data[0]?.hasilLab?.hemoglobin) || '-'}</Text>
                <Text style={styles.penunjang.row3}>{kelamin === 'Laki-Laki' ? '13 - 17' : '12 - 15'} g/dL</Text>
              </View>
              <View style={styles.row} wrap={false}>
                <Text style={styles.penunjang.row1}>Erithrosit (RBC)</Text>
                <Text style={styles.penunjang.row2}>{(data && data[0] && data[0]?.hasilLab?.erithrosit) || '-'}</Text>
                <Text style={styles.penunjang.row3}>{kelamin === 'Laki-Laki' ? '4,7 - 6,1' : '4,2 - 5,4'} /&#181;L</Text>
              </View>
              <View style={styles.row} wrap={false}>
                <Text style={styles.penunjang.row1}>Lekosit (WBC)</Text>
                <Text style={styles.penunjang.row2}>{(data && data[0] && data[0]?.hasilLab?.lekosit) || '-'}</Text>
                <Text style={styles.penunjang.row3}>4.500 - 11.000 /&#181;L</Text>
              </View>
              <View style={styles.row} wrap={false}>
                <Text style={styles.penunjang.row1}>Trombosit (PLT)</Text>
                <Text style={styles.penunjang.row2}>{(data && data[0] && data[0]?.hasilLab?.trombosit) || '-'}</Text>
                <Text style={styles.penunjang.row3}>135.000 - 317.000 /&#181;L</Text>
              </View>
              <View style={styles.row} wrap={false}>
                <Text style={styles.penunjang.row1}>LED</Text>
                <Text style={styles.penunjang.row2}>{(data && data[0] && data[0]?.hasilLab?.led) || '-'}</Text>
                <Text style={styles.penunjang.row3}>0 - 20 mm/jam</Text>
              </View>
              <View style={[styles.row, styles.headerTitle]} wrap={false}>
                <Text style={styles.title.row}>FUNGSI HATI</Text>
              </View>
              <View style={styles.row} wrap={false}>
                <Text style={styles.penunjang.row1}>SGOT</Text>
                <Text style={styles.penunjang.row2}>{(data && data[0] && data[0]?.hasilLab?.sgot) || '-'}</Text>
                <Text style={styles.penunjang.row3}>5 - 40 /&#181;L</Text>
              </View>
              <View style={styles.row} wrap={false}>
                <Text style={styles.penunjang.row1}>SGPT</Text>
                <Text style={styles.penunjang.row2}>{(data && data[0] && data[0]?.hasilLab?.sgpt) || '-'}</Text>
                <Text style={styles.penunjang.row3}>7 - 56 /&#181;L</Text>
              </View>
              <View style={[styles.row, styles.headerTitle]} wrap={false}>
                <Text style={styles.title.row}>FUNGSI GINJAL</Text>
              </View>
              <View style={styles.row} wrap={false}>
                <Text style={styles.penunjang.row1}>UREUM</Text>
                <Text style={styles.penunjang.row2}>{(data && data[0] && data[0]?.hasilLab?.ureum) || '-'}</Text>
                <Text style={styles.penunjang.row3}>{kelamin === 'Laki-Laki' ? '8 - 24' : '6 - 21'} /&#181;L</Text>
              </View>
              <View style={styles.row} wrap={false}>
                <Text style={styles.penunjang.row1}>KREATININ</Text>
                <Text style={styles.penunjang.row2}>{(data && data[0] && data[0]?.hasilLab?.kreatinin) || '-'}</Text>
                <Text style={styles.penunjang.row3}>{kelamin === 'Laki-Laki' ? '0,6 - 1,2' : '0,5 - 1,1'} /&#181;L</Text>
              </View>
              <View style={styles.row} wrap={false}>
                <Text style={styles.penunjang.row1}>ASAM URAT</Text>
                <Text style={styles.penunjang.row2}>{(data && data[0] && data[0]?.hasilLab?.asamUrat) || '-'}</Text>
                <Text style={styles.penunjang.row3}>{kelamin === 'Laki-Laki' ? '3,4 - 7,0' : '2,4 - 6,0'} /&#181;L</Text>
              </View>
            </View>
  
            <Text style={{ textAlign: "right", marginTop: '30px', marginBottom: '30px' }}>PEMERIKSA</Text>
            <Text style={{ textAlign: "right" }}>{(data && data[0] && data[0]?.hasilLab?.pemeriksa)?.toUpperCase() || ''}</Text>
          </div>
        </div>
      );
    }
  }
}

export default PrintForm;