import React, { Component } from "react";
import ResponsiveAppBar from "../../components/NavBar/NavBar";
import ListPasien from "../../fragments/ListPasien";
import FormResume from "../../fragments/FormResume";
import { ref, onValue } from 'firebase/database';
import StartFirebase from '../../configFirebase/index';

const db = StartFirebase();
const pageSize = 5;

class Resume extends Component {
  state = {
    routeTitle: 'List Pasien',
    database: [],
    preliminaryData: [],
    pagination: {
      count: 0,
      from: 0,
      to: pageSize
    },
    loginAs: localStorage.getItem('penunjang')
  }

  componentDidMount() {
    const dbRef = ref(db, 'pasien');
    this.setState({ database: StartFirebase() });
    onValue(dbRef, (snapshot) => {
      let records = [];
      snapshot.forEach(childSnapshot => {
        let data = childSnapshot.val();
        records.push(data);
      });
      this.setState({
        dataTable: records,
        preliminaryData: records,
        pagination: {
          ...this.state.pagination,
          count: records.length
        }
      })
    });
  }

  _handleRoute = (val) => {
    this.setState({ routeTitle: val });
  }

  
  render() {
    const { routeTitle, preliminaryData, loginAs } = this.state;
    let dataPasien;
    if (loginAs === 'Radiologi') {
      dataPasien = (preliminaryData || []).filter(val => val?.dataResume?.needRadiologi && val?.registrasiUlang === true);
    } else {
      dataPasien = (preliminaryData || []).filter(val => val?.dataResume?.needLab && val?.registrasiUlang === true);
    }

    let rendering;
    if (routeTitle === 'List Pasien') {
      rendering = <ListPasien dataPasien={dataPasien}/>
    } else {
      rendering = <FormResume data={dataPasien}/>
    }

    return (
      <div>
        <ResponsiveAppBar route={this._handleRoute} loginAs={loginAs} />
        {rendering}
      </div>
    );
  }
}

export default Resume
