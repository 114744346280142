/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import './styles.css';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Box, FormControl, IconButton, InputAdornment, Modal, OutlinedInput, Pagination, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";
import { Print, Search } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import moment from "moment";
import { Document, PDFViewer, Page, View } from "@react-pdf/renderer";
import PrintForm from "./printForm";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const styleModalForm = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  height: '80%',
  bgcolor: 'background.paper',
  border: '1px solid grey',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4,
};

const viewer = {
  width: '100%',
  height: '600px',
};

const page = {
  backgroundColor: "white",
  color: "black",
  fontSize: 12
};

const section = {
  margin: 10,
  padding: 10,
};

const pageSize = 5;

export default function ListPasien(props) {
  const [state, setState] = useState({
    search: '',
    pagination: { count: 0, from: 0, to: pageSize },
  });
  const [dataTable, setDataTable] = useState([]);
  const [dataPasien, setDataPasien] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setState({
      ...state,
      pagination: {
        ...state.pagination,
        count: props?.dataPasien?.length
      }
    });
  }, [props.dataPasien]);

  useEffect(() => {
    setDataTable(props.dataPasien);
  }, [props?.dataPasien]);

  useEffect(() => {
    const results = props?.dataPasien?.filter((val) => {
      return val?.namaLengkap?.toLowerCase()?.includes(state?.search?.toLowerCase())
    }) ?? [];
    setDataTable(results);
  }, [state?.search]);

  const loginAs = localStorage.getItem('penunjang');

  const _handleChangeSearch = (e) => {
    e.preventDefault();
    setState({ search: e.target.value });
  }

  const _handlePrint = (noReg) => () => {
    let fillData = (dataTable || []).filter(val => val?.noRegistrasi === noReg);
    setDataPasien(fillData);
    setShowModal(true);
  }

  const _handleCloseModal = () => {
    setShowModal(false);
  }

  const _renderModal = () => {
    return (
      <Modal open={showModal} onClose={_handleCloseModal}>
        <Box sx={styleModalForm}>
          <PDFViewer style={viewer}>
            <Document>
              <Page size="A4" style={page} orientation="portrait">
                <View style={section}>
                  <PrintForm data={dataPasien} />
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </Box>
      </Modal>
    )
  } 
  
  const _handleChangePagination = (e, page) => {
    const from = (page - 1) * pageSize;
    const to = (page - 1) * pageSize + pageSize;
    setState({
      ...state,
      pagination: {
        ...state.pagination,
        from, to
      }
    });
  }

  const { search, pagination } = state;
  const newData = dataTable?.slice(pagination?.from, pagination?.to);
  return (
    <div className="containerContent">
      {_renderModal()}
      <FormControl sx={{ marginBottom: 1, marginTop: -1, float: 'right' }}>
        <OutlinedInput
          endAdornment={
            <InputAdornment>
              <Search />
            </InputAdornment>
          }
          onChange={_handleChangeSearch}
          placeholder="Cari pasien..."
          type="text"
          value={search}
          variant="standart"
          size='small'
          color="primary"
        />
      </FormControl>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>NO</StyledTableCell>
              <StyledTableCell>NAMA PASIEN</StyledTableCell>
              <StyledTableCell>NO RM</StyledTableCell>
              <StyledTableCell>ASAL POLIKLINIK</StyledTableCell>
              <StyledTableCell>TANGGAL BERKUNJUNG</StyledTableCell>
              <StyledTableCell>JAM BERKUNJUNG</StyledTableCell>
              <StyledTableCell align='center'>STATUS PASIEN</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataTable?.length === 0 ?
              <StyledTableRow>
                <StyledTableCell colSpan={8}>Tidak Ada Pasien</StyledTableCell>
              </StyledTableRow> :
              (newData || []).map((val, idx) => {
                return (
                  <StyledTableRow key={idx}>
                    <StyledTableCell component="th" scope="row">{idx + 1}</StyledTableCell>
                    <StyledTableCell>{val?.namaLengkap}</StyledTableCell>
                    <StyledTableCell>{val?.noRM}</StyledTableCell>
                    <StyledTableCell>{val?.poli}</StyledTableCell>
                    <StyledTableCell>{moment(val?.tglBerkunjung).format('DD/MM/YYYY')}</StyledTableCell>
                    <StyledTableCell>{val?.jamBerkunjung} WIB</StyledTableCell>
                    <StyledTableCell
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      {(loginAs === 'Radiologi' ? val?.isInspectionRadiologi : val?.isInspectionLab) ?
                        <>
                          <p className='wrapp-register'>Sudah Diperiksa</p>
                        </> : <p className='wrapp-not-register'>Belum Diperiksa</p>}
                    </StyledTableCell>
                    <StyledTableCell>
                        {(loginAs === 'Radiologi' ? val?.isInspectionRadiologi : val?.isInspectionLab) &&
                          <IconButton onClick={_handlePrint(val?.noRegistrasi)}><Print /></IconButton>
                        }
                      </StyledTableCell>
                  </StyledTableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        justifyContent={"flex-end"}
        alignItems={"center"}
        display={"flex"}
        sx={{
          position: 'fixed',
          bottom: '20px',
          right: '10px',
        }}
      >
        <Pagination
          color='primary'
          count={Math.ceil(pagination?.count / pageSize)}
          onChange={_handleChangePagination}
        />
      </Box>
    </div>
  )
}
